<template>
    <transition :class="`modal-basic`" name="slide-up">
        <div v-show="show">
            <div class="container">
                <div class="top">
                    <div class="header">
                        <span class="f-18 c-black">직장 사실 확인</span>
                        <i class="material-icons" @click="$emit('close')">close</i>
                    </div>
                    <div class="desc" v-html="`직장에 대한 정확한 정보 파악을 위해 추가 정보를 요청합니다.`" />
                    <div class="content m-b-24">
                        <div class="employ-type" v-if="mode === 'employment_type'">
                            <p class="f-14 m-b-8">고용형태</p>
                            <div class="btns">
                                <div
                                    :class="{ selected: btn.selected }"
                                    class="btn"
                                    v-for="btn in employBtn"
                                    :key="btn.id"
                                    v-html="btn.name"
                                    @click="clickBtn(btn.id)"
                                />
                            </div>
                        </div>
                        <div class="income" v-else>
                            <p class="f-14 m-b-8">연봉</p>
                            <MoneyInput
                                :mode="`box`"
                                :initVal="initVal"
                                @input="onChangeVal"
                                @focus="needAddInfo = true"
                                @blur="needAddInfo = false"
                            />
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <BottomButton
                        :disabled="disabled"
                        @click="clickSubmit"
                        :label="$translate('NEXT')"
                        :nonFixed="true"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalCompanyAddInfo',
    mounted() {
        setTimeout(() => {
            this.show = true
        })
    },
    data: () => ({
        show: false,
        mode: 'employment_type',
        result: {
            employment_type: null,
            income: null,
        },
        employBtn: [
            {
                id: 0,
                name: '정규직',
                selected: false,
            },
            {
                id: 1,
                name: '계약직',
                selected: false,
            },
            {
                id: 2,
                name: '기타',
                selected: false,
            },
        ],

        // money input
        initVal: 0,
        value: 0,
        needAddInfo: false,
    }),
    computed: {
        disabled() {
            if (this.mode === 'employment_type') return this.result[this.mode] === null

            return !this.result[this.mode]
        },
    },
    methods: {
        close(handler) {
            this.show = false
            setTimeout(() => {
                this.$emit('close')
                if (handler) handler()
            }, 400)
        },
        clickBtn(idx) {
            this.employBtn = this.employBtn.map(btn => ({
                ...btn,
                selected: btn.id === idx,
            }))
            this.result[this.mode] = idx
        },
        clickSubmit() {
            if (this.mode === 'employment_type') {
                this.mode = 'income'
            } else {
                this.result[this.mode] = this.value
                this.$emit('close', this.result)
            }
        },
        onChangeVal(val) {
            this.value = val
            this.result[this.mode] = this.value
        },
    },
}
</script>

<style scoped lang="scss">
.container {
    height: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .bottom {
        margin-bottom: 16px;
    }

    .header {
        padding: 16px 0;
        @include spoqa-f-bold;
        @include between;
    }
    .desc {
        color: $grey-06;
        font-size: 12px;
        margin-bottom: 16px;
    }

    .btns {
        @include between;

        .btn {
            width: calc((100% - 24px) / 3);
            border: 1px solid $grey-04;
            font-size: 14px;

            &.selected {
                border: 1px solid $blue-primary;
                color: $blue-primary;
            }
        }
    }

    ::v-deep .money-input {
        .display {
            height: fit-content;
        }
    }

    ::v-deep .bottom-button {
        button {
            background: black;
        }
    }
}
</style>
